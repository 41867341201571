import React, { useEffect } from "react";
import PropTypes from "prop-types";
import FreeFooter from "../components/common/Footer/FreeFooter/FreeFooter";
import HeaderPlaceholder from "../components/common/HeaderPlaceholder/HeaderPlaceholder";
import Head from "../components/Head/Head";
import Header from "../components/Header/Header";
import useInnerVh from "../hooks/useInnerVh";
import classConcat from "../util/ClassConcat";


const FundingPage = ({ location }) => {
    // Hooks
    useInnerVh();
    useEffect(() => {

    }, []);
    // Render
    const url = "/contact-us/";
    return (
        <>
            <Head />
            <Header pageId={location.pathname} />
            <article className="article">
                <HeaderPlaceholder half />
                <section className={classConcat("container", "section-container")}>
                    <h1 className="section__h1">Funding</h1>
                    <p>
                        <b>Available Funds:</b> OpZoom Fund is a <b>$1 million</b> fund that invests
                        in software technology products. To minimize concentration risk, the maximum
                        investment available to any one company is <b>$100,000</b>.
                    </p>
                    <p>
                        <b>Purpose:</b> If you are looking to accelerate the development
                        of technology that could apply to a valuable market,
                        you will love our fund because it helps with non-dilutive equity funding
                        using&nbsp;
                        <a href={"https://www.investopedia.com/terms/b/botcontract.asp"}>build,
                        operate and transfer</a> plans.
                    </p>
                    <p>
                        <b>Value:</b> Do you want to avoid diluting the equity in your company?
                        Do you want to get started quickly and avoid waiting for investors?
                        If you are looking for a way to quickly create anything between a beta product to
                        a design with a reasonable assurance that it can be built within an acceptable
                        timeframe and budget, you will love this.
                    </p>
                    <p>
                        <b>Term & Cost:</b> All investments have a fixed negotiated funding term.
                        Funds are available as soon as the product is released and available to real users.
                        This funding is attached to a software product and/or a company. The cost is
                        determined based on market information such as this &nbsp;
                        <a href={"https://file.opzoom.com/DealRoomStats.pdf"}>LINK</a> to dealroom.co stats.
                    </p>
                    <p>
                        <b>Qualifications & Requirements:</b> Our investors look for information that
                        helps them decide the likelihood of your success by raising
                        more investment OR earning revenue quickly. So,
                        evidence of your relevant capabilities, experience and partnerships that could
                        attract investments or generate sales in the field are valuable.
                        Our investors value the experience that comes from failure.
                    </p>
                    <p>
                        <b>How to Apply:</b> Please&nbsp;<a href={url}>Contact Us</a> to get the latest information.
                    </p>
                </section>
            </article>
            <FreeFooter />
        </>
    );
};



FundingPage.propTypes = {
    location: PropTypes.shape({
        key: PropTypes.string,
        pathname: PropTypes.string,
        search: PropTypes.string,
        hash: PropTypes.string,
    }).isRequired,
};

export default FundingPage;
